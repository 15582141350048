import { useEffect, useState } from "react";
import { newsList } from "../ajax/thunk";
import axios from "axios";
import PopupNEwsi from "./popupNews";
import data from "./data.json";
export default function Main() {
    const [count, setcount] = useState(8);
    const [news, setnews] = useState(null);
    const [prevList, setprevList] = useState(null);
    const [preterNews, setpreterNews] = useState(null);
    useEffect(() => {
        if (news === null) {
            UL();
            console.log(count);
            // setnews(data.slice(0, count));
            console.log(news);
        } else if (news !== null) {
            let arr = [];

            news.forEach((element, i) => {
              console.log(element);
                let oj = {
                    img: Object || null,
                    imgBig: Object || null,
                    video: String || null,
                    text: String,
                    textBig: String,
                };
                // отбираем превью к новости
                if (element.attachments[0]) {
                    if (element.attachments[0].type ==="photo") {
                      element.attachments[0].photo.sizes.forEach(
                        (el) => {
                            if (el.type === "q") {
                                oj.img = el;
                            }
                            if (el.type === "r") {
                                oj.imgBig = el;
                            }
                        }
                    );
                    } 
                    if (element.attachments[0].type ==="video") {
                      // oj.video = `https://vk.com/video${element.attachments[0].video.owner_id}_${element.attachments[0].video.id}`;
                      oj.video = {};
                      oj.video.oid = element.attachments[0].video.owner_id;
                      oj.video.id = element.attachments[0].video.id;
                    }
                    
                } else if (
                    element.copy_history[0].attachments[0]
                ) {
                  if (element.copy_history[0].attachments[0].type ==="photo") {
                    element.copy_history[0].attachments[0].photo.sizes.forEach(
                      (el) => {
                          if (el.type === "p") {
                              oj.img = el;
                          }
                          if (el.type === "r") {
                              oj.imgBig = el;
                          }
                      }
                  );
                  } 
                  // if (element.attachments[0].type ==="video") {
                  //   oj.video = `https://vk.com/video${element.copy_history.attachments[0].video.owner_id}_${element.copy_history.attachments[0].video.id}`;
                  // }
                    
                }
                //
                if (element.text === "") {
                    oj.textBig =
                        element.copy_history[0].text.replace(
                            /\n/g,
                            "<br />"
                        );
                    oj.text =
                        element.copy_history[0].text.replace(
                            /\n/g,
                            "<br />"
                        );

                    oj.textBig =
                        element.copy_history[0].text.replace(
                            /[^a-zA-Z0-9а-яёА-ЯЁ,.!?:;*()+#<> ]/g,
                            ""
                        );
                    oj.text =
                        element.copy_history[0].text.replace(
                            /[^a-zA-Z0-9а-яёА-ЯЁ,.!?:;*()+#<> ]/g,
                            ""
                        );
                } else {
                    oj.textBig = element.text.replace(
                        /\n/g,
                        "<br />"
                    );
                    oj.text = element.text.replace(
                        /\n/g,
                        "<br />"
                    );
                    oj.textBig = element.text.replace(
                        /[^a-zA-Z0-9а-яёА-ЯЁ,.!?:(*);+#<> ]/g,
                        ""
                    );
                    oj.text = element.text.replace(
                        /[^a-zA-Z0-9а-яёА-ЯЁ,.!?:+(*);+#<> ]/g,
                        ""
                    );
                }

                oj.textBig = oj.text.replace(
                    /[club]|[0-9]{8}|[id]|[0-9]{7}/g,
                    ""
                );

                // oj.textBig=oj.text.replace(/[id]|[0-9]{7}/g,"");

                oj.text = oj.text.replace(
                    /[club]|[0-9]{8}|[id]|[0-9]{7}/g,
                    ""
                );
                // oj.text=oj.text.replace(/[id]|[0-9]{7}/g,"");

                oj.text = oj.text.substring(0, 150);

                oj.text += "...";
                arr[i] = oj;
            });
            setprevList(arr);
        }
    }, [news]);
    function UL() {
        const urlApi = `https://xn--80aachkj4a.xn--p1ai/api.php?count=${count}`;

        axios.get(urlApi).then((resp) => {
              console.log(resp);
            setnews(resp.data);
        });
    }
    useEffect(() => {}, [count]);

    if (prevList === null) {
        // return "loading";
    } else {
        console.log(prevList);
    }
    return (
        <>
            <section className="vibor">
                <div className="fon"></div>
                <div className="container">
                    <div className="vibor_header">
                        <div className="logo">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "../img/logo.png"
                                }
                                alt="Логотип"
                            />
                        </div>
                        <p className="text">
                            Изготовитель: ИП Баталов Н.А.,
                            ИНН 732105911027 Республика
                            Башкортостан, г. Октябрьский.
                            Тел.: 8 (8422)58-48-08. Дата
                            выпуска 02.08.2023. Заказ №88
                            Тираж 10 экз. Кандидат в
                            депутаты по Засвияжскому
                            одномандатному избирательному
                            округу 17 Асташенков Глеб
                            Александрович
                        </p>
                    </div>
                    <div className="vibor_info">
                        <div className="vibor_name">
                            <div className="vibor_name_img">
                                <img
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gleb.jpg"
                                    }
                                    alt=""
                                />
                            </div>
                            <h1>Глеб Асташенков</h1>
                            <p>
                                Кандидат в депутаты по
                                Засвияжскому одномандатному
                                избирательному округу 17
                            </p>
                        </div>
                        <div className="vibor_info_slova">
                            <div className="vibor_info_slova_box">
                                <p>
                                    <b>
                                        Нам здесь жить:
                                        сделаем вместе наш
                                        район уютным и
                                        безопасным. Найдём
                                        решение вашей
                                        проблемы вместе:
                                        пишите в ВК и
                                        Телеграмм
                                    </b>
                                </p>
                            </div>
                        </div>
                        <div className="vibor_info_contact">
                            <div className="link">
                                <img
                                    className="vibor_info_contact_qip"
                                    src="img/tg.png"
                                    alt=""
                                />
                                <a
                                    className="vibor_info_contact_link tg"
                                    href="https://t.me/glebastashenkov"
                                >
                                    @glebastashenkov
                                </a>
                            </div>
                            <div className="link">
                                <img
                                    className="vibor_info_contact_qip"
                                    src="img/vk.png"
                                    alt=""
                                />
                                <a
                                    className="vibor_info_contact_link vk"
                                    href="https://vk.com/g1eb8"
                                >
                                    @g1eb8
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {prevList != null && (
                    <div className="vibor_news">
                        <div className="container">
                            <div className="vibor_news-header">
                                <h2 className="vibor_news_title ">
                                    Новости
                                </h2>
                            </div>
                            <div className="news_list">
                                {prevList.map((e, i) => (
                                    <a
                                        data-item={i}
                                        key={i}
                                        onClick={() =>
                                            setpreterNews(e)
                                        }
                                        className="news__item"
                                    >
                                        {e.img.url
                                          &&
                                          <div
                                            style={{
                                                background: `url(${e.img.url})`,
                                                backgroundRepeat:
                                                    "no-repeat",
                                                backgroundSize:
                                                    "cover",
                                                backgroundPosition:
                                                    "top center",
                                            }}
                                            className="news__item_img"
                                        ></div>
                                        }
                                        {!e.video.raw
                                          &&
                                          <iframe className="news__item_img" src={`https://vk.com/video_ext.php?oid=${e.video.oid}&id=${e.video.id}`}  allow=" encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen></iframe>
                                          // <video className="news__item_img" controls >
                                          //   <source src={e.video} />
                                          // </video>
                                        }
                                        
                                            {/* <img src={e.img.url} alt=""/> */}
                                        
                                        <div className="news__content">
                                            {/* <p className="news__date">28.02.2023</p> */}
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: e.text,
                                                }}
                                                className="news__title"
                                            ></div>
                                        </div>
                                    </a>
                                ))}
                            </div>
                            <div className="news_more_body">
                                <div className="arrow left">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <button
                                    onClick={() => {
                                        setcount(count + 16);
                                        setnews(
                                            news.slice(
                                                0,
                                                count
                                            )
                                        );

                                        // UL();
                                    }}
                                    className="news_more"
                                >
                                    Показать ещё
                                </button>
                                <div className="arrow right">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {preterNews !== null && (
                    <PopupNEwsi
                        setNews={setpreterNews}
                        news={preterNews}
                    />
                )}
            </section>
            <section className="vibor_mob">
                <div className="fon"></div>

                <div className="container">
                    <div className="vibor_header">
                        <div className="logo">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/logo.png"
                                }
                                alt="Логотип"
                            />
                        </div>
                    </div>
                    <div className="vibor_info">
                        <div className="vibor_name">
                            <div className="vibor_name_img">
                                <img
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/gleb.jpg"
                                    }
                                    alt=""
                                />
                            </div>
                            <h1>Глеб Асташенков</h1>
                            <p>
                                Кандидат в депутаты по
                                Засвияжскому одномандатному
                                избирательному округу 17
                            </p>
                        </div>
                        <div className="vibor_info_slova">
                            <div className="vibor_info_slova_box">
                                <p>
                                    <b>
                                        Нам здесь жить:
                                        сделаем вместе наш
                                        район уютным и
                                        безопасным. Найдём
                                        решение вашей
                                        проблемы вместе:
                                        пишите в ВК и
                                        Телеграмм
                                    </b>
                                </p>
                            </div>
                        </div>
                        <div className="vibor_info_contact">
                            <div className="link">
                                <a
                                    className="vibor_info_contact_link tg"
                                    href="https://t.me/glebastashenkov"
                                >
                                    @glebastashenkov
                                </a>
                            </div>
                            <div className="link">
                                <a
                                    className="vibor_info_contact_link vk"
                                    href="https://vk.com/g1eb8"
                                >
                                    @g1eb8
                                </a>
                            </div>
                        </div>
                    </div>
                    <p className="text">
                        Изготовитель: ИП Баталов Н.А., ИНН
                        732105911027 Республика
                        Башкортостан, г. Октябрьский. Тел.:
                        8 (8422)58-48-08. Дата выпуска
                        02.08.2023. Заказ №88 Тираж 10 экз.
                        Кандидат в депутаты по Засвияжскому
                        одномандатному избирательному округу
                        17 Асташенков Глеб Александрович
                    </p>
                </div>
                {prevList !== null && (
                    <div className="vibor_news">
                        <div className="container">
                            <div className="vibor_news-header">
                                <h2 className="vibor_news_title ">
                                    Новости
                                </h2>
                            </div>
                            <div className="news_list">
                                {prevList.map((e) => (
                                    <a
                                        onClick={() =>
                                            setpreterNews(e)
                                        }
                                        className="news__item"
                                    >
                                        <div
                                            style={{
                                                background: `url(${e.img.url})`,
                                                backgroundRepeat:
                                                    "no-repeat",
                                                backgroundSize:
                                                    "cover",
                                                backgroundPosition:
                                                    "top center",
                                            }}
                                            className="news__item_img"
                                        >
                                            {/* <img src={e.img.url} alt=""/> */}
                                        </div>
                                        <div className="news__content">
                                            {/* <p className="news__date">28.02.2023</p> */}
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: e.text,
                                                }}
                                                className="news__title"
                                            ></div>
                                        </div>
                                    </a>
                                ))}
                            </div>
                            <div className="news_more_body">
                                <div className="arrow left">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <button
                                    onClick={() => {
                                        setcount(count + 4);
                                        setnews(
                                            news.slice(
                                                0,
                                                count
                                            )
                                        );

                                        // UL();
                                    }}
                                    className="news_more"
                                >
                                    Показать ещё
                                </button>
                                <div className="arrow right">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {preterNews !== null && (
                    <PopupNEwsi
                        setNews={setpreterNews}
                        news={preterNews}
                    />
                )}
            </section>
        </>
    );
}
