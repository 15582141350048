

export default function PopupNEwsi({news,setNews}) {
    

    return(
        <section className="popupNEwsi">
            <div className="popupNEwsi_body">
                <h2 className="popupNEwsi_title">
                    
                </h2>
                {news.imgBig 
                &&
                <img className="popupNEwsi_img" src={news.imgBig.url} alt="" />
                }
                {
                    !news.video.raw && 
                    <iframe  height="500px"  max-widht="auto" src={`https://vk.com/video_ext.php?oid=${news.video.oid}&id=${news.video.id}`}  allow=" encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen></iframe>

                }
                <div className="text">
                {news.textBig}
                </div>
                <span onClick={()=>setNews(null)} className="popupNEwsi_exit">
                <div className="outer">
  <div className="inner">
    <label>Назад</label>
  </div>
</div>
                </span>
            </div>
        </section>
    )
}